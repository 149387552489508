
















































//import CategoryAdd from "@/components/admin/store/catalog/category/CategoryAdd.vue";
import PlanAdd from "@/components/admin/plan/PlanAdd.vue";
import Vue from "vue";
import PlanService, { CreatePlanDto, GetPlanDto } from "@/api/plan/PlanService";

export default Vue.extend({
  components: {
    PlanAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        producto: 0,
        detalle: "",
        tipoNegocio: ""
      } as CreatePlanDto,
      headers: [
        { text: "#", value: "id" },
        { text: "Detalle", value: "detalle" },
        { text: "Producto", value: "producto" },
        { text: "Tipo Negocio", value: "tipoNegocio" },
        { text: "Estado", value: "getStatusDisplay" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as GetPlanDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        producto: 0,
        detalle: "",
        tipoNegocio: "",
        status: ""
      };
      this.dato = newDto;
    },
    editItem(item: CreatePlanDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      PlanService.getAll()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Planes",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});

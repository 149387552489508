import httpClient from "../http-client";

const ENDPOINT = "/plan-base";

export interface GetPlanDto {
  id: number;
}

export interface CreatePlanDto {
  id?: number;
  producto: number;
  detalle: string;
  tipoNegocio: string;
}

class PlanService {
  getAll(query = ""): Promise<GetPlanDto[]> {
    return httpClient.get(`${ENDPOINT}?${query}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  save(createDto: CreatePlanDto) {
    return httpClient.post(ENDPOINT, createDto);
  }
}
export default new PlanService();


















































//import CategoryAdd from "@/components/admin/store/catalog/category/CategoryAdd.vue";
import CompaniaAdd from "@/components/admin/compañia/CompañiaAdd.vue";
import Vue from "vue";
import CompañiaService, {
  CreateCompañiaDto,
  GetCompañiaDto
} from "@/api/compañia/CompañiaService";

export default Vue.extend({
  components: {
    CompaniaAdd
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: ""
      } as CreateCompañiaDto,
      headers: [
        { text: "#", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as GetCompañiaDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: ""
      };
      this.dato = newDto;
    },
    editItem(item: CreateCompañiaDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      CompañiaService.getAll()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Compañias",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});

import httpClient from "../http-client";

const ENDPOINT = "/compania-base";

export interface GetCompañiaDto {
  id: number;
}

export interface CreateCompañiaDto {
  id?: number;
  nombre: string;
}

class CompañiaService {
  getAll(): Promise<GetCompañiaDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  save(createDto: CreateCompañiaDto) {
    if (createDto.id) {
      return httpClient.put(`${ENDPOINT}/${createDto.id}`, createDto);
    } else {
      return httpClient.post(ENDPOINT, createDto);
    }
  }
}
export default new CompañiaService();















































































import Vue from "vue";
import PlanService from "@/api/plan/PlanService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        producto: 0,
        detalle: "",
        tipoNegocio: "",
        status: ""
      },
      tipoNegocioOptions: [
        { text: "Salud", value: "H" },
        { text: "Vida", value: "L" }
      ],
      statusOptions: [
        { text: "Activo", value: "1" },
        { text: "Inactivo", value: "2" }
      ]
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      PlanService.save(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Plan",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Plan",
            text: "No se pudo crear"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
